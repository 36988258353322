<template>
  <v-container class="mt-3 px-sm-10 px-3 segments-accordion elevation-0" fluid>
    <PageHeader
      header-text="Campaigns"
      regular-button-text="New campaign"
      :regular-button-visible="$store.getters['user/hasWritePermission']"
      @regular-button-click="dialog = true"
    >
      <template #subheader>
        View previous campaigns or initiate a new campaign
      </template>
    </PageHeader>

    <SearchFieldRowWithPagination
      search-field-placeholder="Search for campaigns"
      :current-page-number="campaigns_page_number"
      :current-page-size="campaigns_page_size"
      :count-total="campaigns_total"
      :page-sizes="[25, 50, 100]"
      :search-string="search_string"
      @search="search_campaigns"
      @search-status="search_campaigns($event, true)"
      @next-page="get_campaigns_next_page"
      @prev-page="get_campaigns_prev_page"
      @change-page-size="campaigns_change_page_size"
    >
      <div>
        <v-menu v-model="menuOpened" nudge-top="-5px" :offset-y="true">
          <template #activator="{ on, attrs }">
            <div
              class="simple-text-button button--blue"
              v-bind="attrs"
              v-on="on"
              @click="$emit('click')"
            >
              Filter by Status
            </div>
          </template>
          <OuterList
            title="Filter by Status"
            :items="filter_statuses"
            @click="filter_by_status($event)"
          />
        </v-menu>
      </div>
    </SearchFieldRowWithPagination>

    <CampaignsTable
      v-if="$vuetify.breakpoint.smAndUp"
      :campaigns="campaigns"
      @action="handleAction($event)"
      @image-preview="openPreviewImage($event)"
    />
    <CampaignsTableMobile
      v-else
      :campaigns="campaigns"
      @action="handleAction($event)"
    />

    <v-dialog v-model="dialog" scrollable max-width="560px">
      <SelectCampaignTypeDialog
        :campaign-name="campaign_name"
        @update:name="(newName) => (campaign_name = newName)"
        @dismiss="dialog = false"
        @submit="create_campaign"
      />
    </v-dialog>

    <v-dialog
      v-model="delete_dialog"
      scrollable
      max-width="360px"
      @click:outside="delete_item_id = null"
    >
      <DeleteConfirmationDialog
        v-if="delete_dialog"
        header="Archive Campaign"
        subheader="Do you really want to archive campaign?"
        action_button_name="Archive"
        @delete="delete_campaign()"
        @dismiss="
          delete_dialog = false;
          delete_item_id = null;
        "
      />
    </v-dialog>

    <v-dialog
      v-model="clone_dialog"
      max-width="560px"
      @click:outside="clone_item_id = null"
    >
      <CloneCampaignDialog
        v-if="clone_dialog"
        @dismiss="
          clone_dialog = false;
          clone_item_id = null;
        "
        @save="clone_campaign"
      />
    </v-dialog>

    <v-dialog
      v-model="cancel_dialog"
      scrollable
      max-width="360px"
      @click:outside="cancel_item_id = null"
    >
      <DeleteConfirmationDialog
        v-if="cancel_dialog"
        header="Cancel Campaign"
        subheader="Do you really want to cancel campaign?"
        action_button_name="Confirm"
        @delete="cancel_campaign()"
        @dismiss="
          cancel_dialog = false;
          cancel_item_id = null;
        "
      />
    </v-dialog>
    <v-dialog v-model="previewImageDialog" scrollable>
      <ImagePreviewDialog
        v-if="previewImageDialog"
        :image-url="previewImageUrl"
        :full-image-url="previewFullImageUrl"
        :name="previewImageName"
        @dismiss="previewImageDialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import ImagePreviewDialog from "@/sharedComponents/ImagePreviewDialog";
import SearchFieldRowWithPagination from "@/sharedComponents/SearchFieldRowWithPagination";
import SelectCampaignTypeDialog from "@/views/Campaigns/SelectCampaignType";
import CampaignsTable from "@/views/Campaigns/components/CampaignsTable";
import CampaignsTableMobile from "@/views/Campaigns/components/CampaignsTableMobile";
import OuterList from "@/sharedComponents/OuterList";
import DeleteConfirmationDialog from "@/sharedComponents/DeleteConfirmationDialog";
import CloneCampaignDialog from "@/views/Campaigns/CloneCampaignDialog";

export default {
  name: "CampaignsView",
  metaInfo: {
    title: "Campaign Marketing",
  },
  components: {
    OuterList,
    SelectCampaignTypeDialog,
    SearchFieldRowWithPagination,
    PageHeader,
    CampaignsTable,
    CampaignsTableMobile,
    DeleteConfirmationDialog,
    ImagePreviewDialog,
    CloneCampaignDialog,
  },
  data() {
    return {
      menuOpened: false,
      filter_statuses: [
        { label: "Draft", value: "draft", selected: false },
        { label: "Scheduled", value: "scheduled", selected: false },
        { label: "Preparing", value: "preparing", selected: false },
        { label: "Live", value: "live", selected: false },
        { label: "Completed", value: "completed", selected: false },
        { label: "Canceled", value: "canceled", selected: false },
      ],
      search_string: "",
      search_status: "",
      campaign_name: "",
      dialog: false,
      delete_dialog: false,
      cancel_dialog: false,
      clone_dialog: false,
      delete_item_id: null,
      clone_item_id: null,
      clone_type: "",
      cancel_item_id: null,
      selectedCampaignTypeKey: null,
      campaigns: [],
      campaigns_total: 0,
      campaigns_page_number: 1,
      campaigns_page_size: 25,
      previewImageDialog: false,
      previewImageUrl: null,
      previewFullImageUrl: null,
      previewImageName: null,
    };
  },
  created() {
    this.search_campaigns();
  },
  methods: {
    openPreviewImage({ name, image }) {
      this.previewImageUrl = image.medium || image.large || image.full || image.small;
      this.previewFullImageUrl = image.full || image.large || image.medium || image.small;
      this.previewImageName = name;
      this.previewImageDialog = !!this.previewImageUrl;
    },
    async filter_by_status(status) {
      let elem = this.filter_statuses.filter(
        (i) => i.value === status.value
      )[0];
      elem.selected = !elem.selected;
      this.filter_statuses
        .filter((i) => i.value !== status.value)
        .forEach((i) => (i.selected = false));
      await this.search_campaigns();
    },

    async clone_campaign(name) {
      const response = await this.$rest.campaign.clone_resource(
        this.clone_item_id,
        name
      );
      if (response) {
        if (this.clone_type == "automation")
          this.$router.push({
            name: "AutomatedCampaign",
            params: { id: response.data.id },
          });
        if (this.clone_type == "simple")
          this.$router.push({
            name: "SimpleCampaign",
            params: { id: response.data.id },
          });
        if (this.clone_type == "split-test")
          this.$router.push({
            name: "SimpleCampaign",
            params: { id: response.data.id },
          });
      }
    },

    async search_campaigns(search_string) {
      if (search_string) {
        this.search_string = search_string;
      }
      const resp = await this.$rest.campaign.get_collection({
        includeCreativeImages: true,
        limit: this.campaigns_page_size,
        page: this.campaigns_page_number,
        name: this.search_string,
        sortDraft: true,
        type: ['simple', 'split-test', 'automation'],
        status: this.filter_statuses
          .filter((i) => i.selected)
          .map((i) => i.value),
        sort: ["startDate:desc"],
        isArchived: false,
      });
      this.campaigns = resp.data.items;
      this.campaigns_total = resp.data.totalCount;
    },
    async create_campaign(type) {
      const campaign = await this.$rest.campaign.post_resource({
        name: this.campaign_name,
        type,
      });
      switch (type) {
        case "automation":
          await this.$router.push({
            name: "AutomatedCampaign",
            params: { id: campaign.data.id },
          });
          break;
        case "simple":
          await this.$router.push({
            name: "SimpleCampaign",
            params: { id: campaign.data.id },
          });
          break;
        case "split-test":
          await this.$router.push({
            name: "SimpleCampaign",
            params: { id: campaign.data.id },
          });
          break;
      }
    },
    async delete_campaign() {
      this.delete_dialog = false;
      const campaign_to_delete = this.campaigns.find(
        (item) => item.id === this.delete_item_id
      );

      await this.$rest.campaign.put_resource(this.delete_item_id, {
        ...campaign_to_delete,
        isArchived: true,
      });
      await this.search_campaigns();
    },
    async cancel_campaign() {
      this.cancel_dialog = false;
      const campaign_to_cancel = this.campaigns.find(
        (item) => item.id === this.cancel_item_id
      );

      await this.$rest.campaign.put_resource(this.cancel_item_id, {
        ...campaign_to_cancel,
        status: "canceled",
      });
      await this.search_campaigns();
    },

    async handleAction(action) {
      if (action.event.value === "delete") {
        this.delete_dialog = true;
        this.delete_item_id = action.item.id;
      } else if (action.event.value === "cancel") {
        this.cancel_dialog = true;
        this.cancel_item_id = action.item.id;
      } else if (action.event.value === "clone") {
        this.clone_dialog = true;
        this.clone_item_id = action.item.id;
        this.clone_type = action.item.type;
      } else if (action.event.value === "edit") {
        switch (action.item.type) {
          case "automation":
            await this.$router.push({
              name: "AutomatedCampaign",
              params: { id: action.item.id },
            });
            break;
          case "simple":
            await this.$router.push({
              name: "SimpleCampaign",
              params: { id: action.item.id },
            });
            break;
          case "split-test":
            await this.$router.push({
              name: "SimpleCampaign",
              params: { id: action.item.id },
            });
            break;
        }
      } else if (action.event.value === "report") {
        await this.$router.push({
          name: "EmailReport",
          query: { selectedCampaignIDs: [action.item.id] },
        });
      } else if (action.event.value === "view") {
        switch (action.item.type) {
          case "automation":
            await this.$router.push({
              name: "AutomatedCampaign",
              params: { id: action.item.id },
            });
            break;
          case "simple":
            await this.$router.push({
              name: "SimpleCampaign",
              params: { id: action.item.id },
            });
            break;
          case "split-test":
            await this.$router.push({
              name: "SimpleCampaign",
              params: { id: action.item.id },
            });
            break;
        }
      }
    },
    async campaigns_change_page_size(size) {
      this.campaigns_page_size = size;
      this.campaigns_page_number = 1;
      await this.search_campaigns();
    },
    async get_campaigns_next_page() {
      this.campaigns_page_number += 1;
      await this.search_campaigns();
    },
    async get_campaigns_prev_page() {
      this.campaigns_page_number -= 1;
      await this.search_campaigns();
    },
  },
};
</script>
<style scoped>
.custom-button--blue {
  background-color: #2b84eb !important;
}
.simple-text-button {
  font-weight: 500;
}
</style>
