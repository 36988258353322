<template>
  <div class="status-icon">
    <div v-if="type === 'live'" style="color: #1f9f43">
      <v-icon color="#1f9f43" class="mr-1" size="14px">
        mdi-play-outline
      </v-icon>Live
    </div>
    <div v-if="type === 'draft'" style="color: #e4a61a">
      <v-icon color="#e4a61a" class="mr-1" size="14px">
        mdi-file-document-outline
      </v-icon>Draft
    </div>
    <div v-if="type === 'completed'" style="color: #2b84eb">
      <v-icon color="#2b84eb" class="mr-1" size="14px">
        mdi-check
      </v-icon>Completed
    </div>
    <div v-if="type === 'canceled'" style="color: #dc2929">
      <v-icon color="#dc2929" class="mr-1" size="14px">
        mdi-close
      </v-icon>Canceled
    </div>
    <div v-if="type === 'scheduled'" style="color: #084EFF">
      <v-icon color="#084EFF" class="mr-1" size="14px">
        mdi-clock-outline
      </v-icon>Scheduled
    </div>
    <div v-if="type === 'preparing'" style="color: #32612D">
      <v-icon color="#32612D" class="mr-1" size="14px">
        mdi-cogs
      </v-icon>Preparing
    </div>
  </div>
</template>

<script>
export default {
  name: "StatusIcon",
  props: {
    type: {
      default: () => "live",
    },
  },
};
</script>

<style scoped>
.status-icon > div {
  white-space: nowrap;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
}
</style>
