var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"campaigns-table-mobile pa-0",attrs:{"fluid":""}},_vm._l((_vm.campaigns),function(item,i){return _c('v-container',{key:i,staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-divider'),_c('v-row',{staticClass:"pt-1"},[_c('v-col',{attrs:{"cols":"4"}},[(
            !Array.isArray(item.images) && Object.keys(item.images).length > 0
          )?_c('v-carousel',{staticClass:"campaigns-carousel",attrs:{"height":"95","hide-delimiters":""},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
          var on = ref.on;
return [_c('div',_vm._g({},on),[_c('i',{staticClass:"mdi mdi-chevron-left pointer",staticStyle:{"background-color":"white","padding":"10px 0"}})])]}},{key:"next",fn:function(ref){
          var on = ref.on;
return [_c('div',[_c('div',_vm._g({},on),[_c('i',{staticClass:"mdi mdi-chevron-right pointer",staticStyle:{"background-color":"white","padding":"10px 0"}})])])]}}],null,true)},_vm._l((item.images),function(image,key){return _c('v-carousel-item',{key:key},[_c('v-img',{staticClass:"my-4",staticStyle:{"cursor":"pointer"},attrs:{"max-height":"95px","contain":"","src":image.small || image.medium || image.full},on:{"click":function($event){return _vm.$emit('image-preview', { name: item.name, image: image.medium })}}})],1)}),1):_c('v-img',{staticClass:"my-4",attrs:{"max-height":"95px","contain":"","src":require("@/assets/default-thumbnail.png")}})],1),_c('v-col',{staticClass:"d-flex flex-column justify-center"},[_c('div',{staticClass:"campaigns-table__campaign-header mb-1"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"campaigns-table__campaign-subheader"},[_c('div',{staticClass:"pt-1"},[_c('span',{staticClass:"font-weight-bold black--text"},[_vm._v("Type: ")]),_vm._v(" "+_vm._s(item.type.toLocaleString())+" ")]),_c('div',{staticClass:"pt-1",attrs:{"title":_vm.getDateWithDayNameAndTime(item.createdAt)}},[_c('span',{staticClass:"font-weight-bold black--text"},[_vm._v("Created: ")]),_vm._v(" "+_vm._s(_vm.getDate(item.createdAt))+" ")]),(item.displayStartDate)?_c('div',{staticClass:"pt-1"},[_c('span',{staticClass:"font-weight-bold black--text"},[_vm._v("Scheduled: ")]),_vm._v(" "+_vm._s(item.displayStartDate)+" ")]):_vm._e()])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.getOuterListItems(item.status).length)?_c('v-menu',{attrs:{"max-width":"164px","nudge-top":"-5px","offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"elevation-0 panel-button mb-1",class:{ 'panel-button--active': _vm.menuOpened },attrs:{"height":"34px","block":"","plain":""},on:{"click":function($event){return _vm.$emit('click')}}},'v-btn',attrs,false),on),[_vm._v(" Actions ")])]}}],null,true)},[_c('OuterList',{attrs:{"title":"Actions","items":_vm.getOuterListItems(item.status)},on:{"click":function($event){return _vm.$emit('action', { event: $event, item: item })}}})],1):_vm._e()],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }