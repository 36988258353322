<template>
  <div class="campaigns-table">
    <v-simple-table>
      <thead>
        <tr class="campaigns-table__header">
          <th />
          <th>Campaign</th>
          <th>Delivered</th>
          <th>Opens</th>
          <th>Clicks</th>
          <th>Unsubscribes</th>
          <th>Status</th>
          <th />
        </tr>
      </thead>
      <tbody class="search-table__content">
        <template v-if="campaigns.length > 0">
          <tr v-for="(item, i) in campaigns" :key="i">
            <td style="max-width: 132px">
              <v-carousel
                v-if="
                  !Array.isArray(item.images) &&
                    Object.keys(item.images).length > 0
                "
                class="campaigns-carousel my-4"
                height="95"
                hide-delimiters
              >
                <template #prev="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <i
                      class="mdi mdi-chevron-left pointer"
                      style="background-color: white; padding: 10px 0"
                    />
                  </div>
                </template>
                <template #next="{ on, attrs }">
                  <div>
                    <div v-bind="attrs" v-on="on">
                      <i
                        class="mdi mdi-chevron-right pointer"
                        style="background-color: white; padding: 10px 0"
                      />
                    </div>
                  </div>
                </template>
                <v-carousel-item v-for="(image, key) in item.images" :key="key">
                  <v-img
                    max-height="95px"
                    contain
                    style="cursor: pointer"
                    lazy-src="@/assets/channels/campaign.jpg"
                    :src="image.small || image.medium || image.full"
                    @click="
                      $emit('image-preview', { name: item.name, image })
                    "
                  />
                </v-carousel-item>
              </v-carousel>
              <v-img
                v-else
                max-height="95px"
                contain
                class="my-4"
                src="~@/assets/channels/campaign.jpg"
              />
            </td>

            <td class="py-1">
              <div class="campaigns-table__campaign-header mb-1">
                {{ item.name }}
                <div class="text--disabled" style="font-size: 11px;">
                  ID: {{ item.id }}
                </div>
              </div>
              <div class="campaigns-table__campaign-subheader">
                <div class="pt-1">
                  <span class="font-weight-bold black--text">Type: </span>
                  {{ item.type.toLocaleString() }}
                </div>
                <div
                  class="pt-1"
                  :title="getDateWithDayNameAndTime(item.createdAt)"
                >
                  <span class="font-weight-bold black--text">Created: </span>
                  {{ getDate(item.createdAt) }}
                </div>
                <div v-if="item.displayStartDate" class="pt-1">
                  <span class="font-weight-bold black--text">Scheduled: </span>
                  {{ item.displayStartDate }}
                </div>
              </div>
            </td>
            <td>
              <span class="percentage">{{ getDeliveredPercentage(item) }}</span>
            </td>
            <td>
              <span class="percentage">{{ getOpensPercentage(item) }}</span>
            </td>
            <td>
              <span class="percentage">{{ getClicksPercentage(item) }}</span>
            </td>
            <td>
              <span class="percentage">{{ getUnsubscribesPercentage(item) }}</span>
            </td>
            <td>
              <StatusIcon :type="item.status" />
            </td>
            <td class="v-menu-attach-parent">
              <v-menu
                v-if="getOuterListItems(item.status).length"
                nudge-top="-5px"
                nudge-left="15px"
                :offset-y="true"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="elevation-0 outlined-btn px-12"
                    height="34px"
                    plain
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('click')"
                  >
                    Actions
                  </v-btn>
                </template>
                <OuterList
                  title="Actions"
                  :items="getOuterListItems(item)"
                  @click="$emit('action', { event: $event, item })"
                />
              </v-menu>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="8">
              <div>
                You have not set up a campaign yet! Let's fix that, by clicking
                the 'New Campaign' button above.
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import StatusIcon from "@/views/Campaigns/components/StatusIcon";
import OuterList from "@/sharedComponents/OuterList";
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "CampaignsTable",
  components: { StatusIcon, OuterList },
  mixins: [datesMixin],
  props: {
    campaigns: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      menuOpened: false,
    };
  },
  methods: {
    getOuterListItems(item) {
      const status = item.status;
      let viewPath = null;

      switch (item.type) {
        case "automation":
          viewPath = this.$router.resolve({
            name: "AutomatedCampaign",
            params: { id: item.id },
          }).href;
          break;
        case "simple":
          viewPath = this.$router.resolve({
            name: "SimpleCampaign",
            params: { id: item.id },
          }).href;
          break;
        case "split-test":
          viewPath = this.$router.resolve({
            name: "SimpleCampaign",
            params: { id: item.id },
          }).href;
          break;
      }

      const reportPath = this.$router.resolve({
        name: "EmailReport",
        query: { selectedCampaignIDs: [item.id] },
      }).href;

      if (!this.$store.getters["user/hasWritePermission"]) {
        if (['live', 'completed', 'canceled', 'preparing'].includes(status)) {
          const options = [{
            label: "View Campaign",
            icon: "mdi-account-eye-outline",
            value: "view",
            route: viewPath,
          }];

          if (['live', 'completed'].includes(status)) {
            options.push({
              label: "Show Reports",
              icon: "mdi-chart-arc",
              value: "report",
              route: reportPath,
            });
          }

          return options;
        }

        return [];
      }

      switch (status) {
        case "draft":
          return [
            { label: "Edit Campaign", icon: "mdi-pencil", value: "edit", route: viewPath },
            { label: "Clone Campaign", icon: "$copy", value: "clone" },
            { label: "Archive Campaign", icon: "mdi-delete-outline", value: "delete" },
          ];
        case "scheduled":
          return [
            { label: "Edit Campaign", icon: "mdi-pencil", value: "edit", route: viewPath },
            { label: "Clone Campaign", icon: "$copy", value: "clone" },
            { label: "Cancel Campaign", icon: "mdi-cancel", value: "cancel" },
          ];
        case "preparing":
          return [
            { label: "View Campaign", icon: "mdi-account-eye-outline", value: "view", route: viewPath },
            { label: "Clone Campaign", icon: "$copy", value: "clone" },
            { label: "Cancel Campaign", icon: "mdi-cancel", value: "cancel" },
          ];
        case "live":
          return [
            {
              label: "View Campaign",
              icon: "mdi-account-eye-outline",
              value: "view",
              route: viewPath,
            },
            {
              label: "Show Reports",
              icon: "mdi-chart-arc",
              value: "report",
              route: reportPath,
            },
            { label: "Clone Campaign", icon: "$copy", value: "clone" },
            { label: "Cancel Campaign", icon: "mdi-cancel", value: "cancel" }
          ];
        case "completed":
          return [
            {
              label: "View Campaign",
              icon: "mdi-account-eye-outline",
              value: "view",
              route: viewPath,
            },
            {
              label: "Show Reports",
              icon: "mdi-chart-arc",
              value: "report",
              route: reportPath,
            },
            { label: "Clone Campaign", icon: "$copy", value: "clone" },
            { label: "Archive Campaign", icon: "mdi-delete-outline", value: "delete" },
          ];
        case "canceled":
          return [
            {
              label: "View Campaign",
              icon: "mdi-account-eye-outline",
              value: "view",
              route: viewPath,
            },
            { label: "Clone Campaign", icon: "$copy", value: "clone" },
            { label: "Archive Campaign", icon: "mdi-delete-outline", value: "delete" },
          ];
      }

      return [
        { label: "Edit Campaign", icon: "mdi-pencil", value: "edit", route: viewPath },
        { label: "Clone Campaign", icon: "$copy", value: "clone" },
        {
          label: "Cancel Campaign",
          icon: "mdi-cancel",
          value: "cancel",
        },
      ];
    },
    getDeliveredCount(statistics) {
      return (statistics?.sends ?? 0) - (statistics?.hardBounces ?? 0) - (statistics?.softBounces ?? 0);
    },
    getDeliveredPercentage(item) {
      if (!item.statistics?.email?.sends) {
        return '-';
      }
      
      if (item.statistics.email.sends === 0) {
        return '0%';
      }
      
      return (
        (this.getDeliveredCount(item.statistics.email) / item.statistics.email.sends) *
        100
      ).toFixed(2) + '%';
    },
    getPercentageForValue(item, value) {
      if (value === undefined) {
        return '-';
      }

      const deliveredCount = this.getDeliveredCount(item.statistics.email);

      if (deliveredCount === 0) {
        return '0%';
      }

      return ((value / deliveredCount) * 100).toFixed(2) + '%';
    },
    getOpensPercentage(item) {
      return this.getPercentageForValue(item, item.statistics?.email?.uniqueOpens);
    },
    getClicksPercentage(item) {
      return this.getPercentageForValue(item, item.statistics?.email?.uniqueClicks);
    },
    getUnsubscribesPercentage(item) {
      return this.getPercentageForValue(item, item.statistics?.email?.unsubscribes);
    },
  },
};
</script>

<style lang="scss" scoped>
.campaigns-table {
  // .v-data-table {
  &__header {
    th {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
    }
  }
  &__campaign-header {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    color: #241c15;
  }
  &__campaign-subheader {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    line-height: normal;
    color: #66788e;
  }
  .percentage {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
    color: #2b84eb;
  }
  .v-menu-button:focus {
    background: #2b84eb !important;
    opacity: 1;
    .v-btn__content {
      color: white;
    }
  }

  thead {
    tr > th {
      border-bottom: solid 1px #dee7ee !important;
    }
  }
  tbody {
    // table border colors
    tr > td {
      border-bottom: solid 1px #dee7ee;
    }
    tr:hover {
      background: inherit !important;
    }
  }
  // }
}
.panel-button {
  font-family: "Open Sans", sans-serif;
  font-size: 13px !important;
  color: #66788e;
  text-transform: none;
  background: #ecf1f5 !important;
  font-weight: 600 !important;
}

.campaigns-carousel .v-window__prev,
.campaigns-carousel .v-window__next {
  margin: 16px 0;
}

.pointer {
  cursor: pointer;
}
</style>
