import { render, staticRenderFns } from "./CampaignsTable.vue?vue&type=template&id=045a8135&scoped=true"
import script from "./CampaignsTable.vue?vue&type=script&lang=js"
export * from "./CampaignsTable.vue?vue&type=script&lang=js"
import style0 from "./CampaignsTable.vue?vue&type=style&index=0&id=045a8135&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "045a8135",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VCarousel,VCarouselItem,VImg,VMenu,VSimpleTable})
