<template>
  <v-container class="campaigns-table-mobile pa-0" fluid>
    <v-container v-for="(item, i) in campaigns" :key="i" class="pa-0" fluid>
      <v-divider />
      <v-row class="pt-1">
        <v-col cols="4">
          <v-carousel
            v-if="
              !Array.isArray(item.images) && Object.keys(item.images).length > 0
            "
            class="campaigns-carousel"
            height="95"
            hide-delimiters
          >
            <template #prev="{ on }">
              <div v-on="on">
                <i
                  class="mdi mdi-chevron-left pointer"
                  style="background-color: white; padding: 10px 0"
                />
              </div>
            </template>
            <template #next="{ on }">
              <div>
                <div v-on="on">
                  <i
                    class="mdi mdi-chevron-right pointer"
                    style="background-color: white; padding: 10px 0"
                  />
                </div>
              </div>
            </template>
            <v-carousel-item v-for="(image, key) in item.images" :key="key">
              <v-img
                max-height="95px"
                contain
                class="my-4"
                style="cursor: pointer"
                :src="image.small || image.medium || image.full"
                @click="
                  $emit('image-preview', { name: item.name, image: image.medium })
                "
              />
            </v-carousel-item>
          </v-carousel>
          <v-img
            v-else
            max-height="95px"
            contain
            class="my-4"
            src="~@/assets/default-thumbnail.png"
          />
        </v-col>
        <v-col class="d-flex flex-column justify-center">
          <div class="campaigns-table__campaign-header mb-1">
            {{ item.name }}
          </div>
          <div class="campaigns-table__campaign-subheader">
            <div class="pt-1">
              <span class="font-weight-bold black--text">Type: </span>
              {{ item.type.toLocaleString() }}
            </div>
            <div
              class="pt-1"
              :title="getDateWithDayNameAndTime(item.createdAt)"
            >
              <span class="font-weight-bold black--text">Created: </span>
              {{ getDate(item.createdAt) }}
            </div>
            <div v-if="item.displayStartDate" class="pt-1">
              <span class="font-weight-bold black--text">Scheduled: </span>
              {{ item.displayStartDate }}
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-menu
            v-if="getOuterListItems(item.status).length"
            max-width="164px"
            nudge-top="-5px"
            :offset-y="true"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                class="elevation-0 panel-button mb-1"
                :class="{ 'panel-button--active': menuOpened }"
                height="34px"
                block
                plain
                v-bind="attrs"
                v-on="on"
                @click="$emit('click')"
              >
                Actions
              </v-btn>
            </template>
            <OuterList
              title="Actions"
              :items="getOuterListItems(item.status)"
              @click="$emit('action', { event: $event, item })"
            />
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import OuterList from "@/sharedComponents/OuterList";
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "CampaignsTable",
  components: { OuterList },
  mixins: [datesMixin],
  props: {
    campaigns: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      menuOpened: false,
      outerListItems: [
        { label: "Edit Campaign", icon: "mdi-pencil", value: "edit" },
        { label: "Show Reports", icon: "mdi-chart-arc", value: "report" },
        {
          label: "Delete Campaign",
          icon: "mdi-delete-outline",
          value: "delete",
        },
      ],
    };
  },
  methods: {
    //before this was false for pulse admin and pulse user
    getOuterListItems(status) {
      if (!this.$store.getters["user/hasWritePermission"]) {
        if (['live', 'completed', 'canceled', 'preparing'].includes(status)) {
          return [
            {
              label: "View Campaign",
              icon: "mdi-account-eye-outline",
              value: "view",
            },
            {label: "Show Reports", icon: "mdi-chart-arc", value: "report"},
          ];
        }

        return [];
      }

      switch (status) {
        case 'draft':
          return [
            { label: "Edit Campaign", icon: "mdi-pencil", value: "edit" },
            { label: "Archive Campaign", icon: "mdi-delete-outline", value: "delete" },
          ];
        case 'scheduled':
          return [
            { label: "Edit Campaign", icon: "mdi-pencil", value: "edit" },
            { label: "Cancel Campaign", icon: "mdi-cancel", value: "cancel" },
          ];
        case 'preparing':
          return [
            { label: "View Campaign", icon: "mdi-account-eye-outline", value: "view" },
            { label: "Cancel Campaign", icon: "mdi-cancel", value: "cancel" },
          ];
        case 'live':
          return [
            { label: "View Campaign", icon: "mdi-account-eye-outline", value: "view" },
            { label: "Show Reports", icon: "mdi-chart-arc", value: "report" },
            { label: "Cancel Campaign", icon: "mdi-cancel", value: "cancel" }
          ];
        case 'completed':
        case 'canceled':
          return [
            { label: "View Campaign", icon: "mdi-account-eye-outline", value: "view" },
            { label: "Show Reports", icon: "mdi-chart-arc", value: "report" },
            { label: "Archive Campaign", icon: "mdi-delete-outline", value: "delete" },
          ];
      }

      return [
        { label: "Edit Campaign", icon: "mdi-pencil", value: "edit" },
        { label: "Clone Campaign", icon: "$copy", value: "clone" },
        {
          label: "Cancel Campaign",
          icon: "mdi-cancel",
          value: "cancel",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.campaigns-table-mobile {
  &__campaign-header {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    color: #241c15;
  }
  &__campaign-subheader {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    line-height: normal;
    color: #66788e;
  }
  .panel-button {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #66788e;
    text-transform: none;
    background: #ecf1f5 !important;
  }
  .menuable__content__active ~ button {
    background: #2b84eb !important;
    .v-btn__content {
      color: white;
    }
  }
}

.campaigns-carousel .v-window__prev,
.campaigns-carousel .v-window__next {
  margin: 16px 0;
}

.pointer {
  cursor: pointer;
}
</style>
