<template>
  <v-card class="popup-card">
    <v-card-title class="px-6 pt-4 pb-4">
      New Campaign
    </v-card-title>
    <v-divider v-if="$vuetify.breakpoint.xsOnly" />
    <v-card-text class="px-6 pt-5">
      <div class="input-label mb-4">
        Campaign Type
      </div>
      <v-row class="px-2 mb-4">
        <v-col
          v-for="type in contact_types"
          :key="type.header"
          class="pa-0 mb-4"
          cols="12"
          sm="6"
        >
          <BoxTypeSelector
            class="mx-0"
            :header="type.header"
            :subheader="type.subheader"
            :icon="type.icon"
            :value="selected_contact_type === type.key"
            :item-key="type.key"
            @input="selected_contact_type = type.key"
          />
        </v-col>
      </v-row>
      <div class="input-label">
        Campaign Name
      </div>
      <validation-observer ref="textInput">
        <CustomTextInput
          :value="campaignName"
          class="flex-grow-1"
          placeholder="My Campaign"
          :maxlength="150"
          required
          @input="$emit('update:name', $event)"
        />
      </validation-observer>
      <v-card-actions class="d-flex justify-end align-center pa-0 mt-5">
        <div
          class="simple-text-button button--gray px-9"
          @click="$emit('dismiss')"
        >
          Cancel
        </div>

        <v-btn
          class="custom-button custom-button--blue px-15"
          height="34px"
          depressed
          :disabled="!selected_contact_type"
          @click="onStart"
        >
          Start
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import BoxTypeSelector from "@/sharedComponents/BoxTypeSelectorSmall";
import CustomTextInput from "@/sharedComponents/CustomTextInput";

export default {
  name: "SelectCampaignType",
  metaInfo: {
    title: 'Select Campaign'
  },
  components: { BoxTypeSelector, CustomTextInput },
  props: {
    campaignName: {
      default: () => null,
      type: String,
    },
  },
  data() {
    return {
      selected_contact_type: false,
      contact_types: [
        {
          icon: "$person",
          header: "Simple A/B",
          subheader: "Get started quickly",
          key: "simple",
        },
        {
          icon: "$list",
          header: "Automated",
          subheader: "More complex campaigns",
          key: "automation",
        },
      ],
    };
  },
  methods: {
    async onStart() {
      try {
        if (await this.validate()) {
          this.$emit("submit", this.selected_contact_type);
        }
      } catch (e) {
        console.error(e)
      }
    },
    async validate() {
      return await this.$refs.textInput.validate();
    },
    reset() {
      this.$refs.textInput.reset();
    },
    resetValidation() {
      this.$refs.textInput.resetValidation();
    },
  },
};
</script>
